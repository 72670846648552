<template>
  <v-data-table
    :headers="headers"
    :items="report_add_cashs"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>

        <ModalAddCredit :customer_id="customer_id"></ModalAddCredit>
<!--        <v-dialog-->
<!--          v-model="dialogCredit"-->
<!--          max-width="500px"-->
<!--        >-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            &lt;!&ndash; <div class="d-flex justify-space-between"> &ndash;&gt;-->
<!--            &lt;!&ndash; <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> &ndash;&gt;-->
<!--            <v-btn-->
<!--              color="primary"-->
<!--              dark-->
<!--              class="mb-2 mr-2"-->
<!--              v-bind="attrs"-->
<!--              v-on="on"-->
<!--            >-->
<!--              เติมเครดิต-->
<!--            </v-btn>-->
<!--            &lt;!&ndash; </div> &ndash;&gt;-->
<!--          </template>-->
<!--          <v-card>-->
<!--            <v-card-title>-->
<!--              <span class="text-h5">{{ formCredit }}</span>-->
<!--            </v-card-title>-->

<!--            <v-card-text>-->
<!--              <v-container>-->
<!--                <v-row>-->
<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12"-->
<!--                  >-->
<!--                    <v-text-field-->
<!--                      v-model="editedItem.numberCustomer"-->
<!--                      label="ลูกค้า"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->


<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12"-->
<!--                  >-->
<!--                    <v-text-field-->
<!--                      v-model="editedItem.numberCustomer"-->
<!--                      label="ประเภทแพ็กเกจ"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->

<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12"-->
<!--                  >-->
<!--                    <v-text-field-->
<!--                      v-model="editedItem.status"-->
<!--                      label="จำนวนเครดิต"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-col-->
<!--                    cols="12"-->
<!--                    sm="12"-->
<!--                    md="12"-->
<!--                  >-->
<!--                    <v-text-field-->
<!--                      v-model="editedItem.status"-->
<!--                      label="หมายเหตุ"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-container>-->
<!--            </v-card-text>-->

<!--            <v-card-actions>-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn-->
<!--                color="error"-->
<!--                text-->
<!--                @click="close"-->
<!--              >-->
<!--                Cancel-->
<!--              </v-btn>-->
<!--              <v-btn-->
<!--                color="primary"-->
<!--                text-->
<!--                @click="save"-->
<!--              >-->
<!--                Save-->
<!--              </v-btn>-->
<!--            </v-card-actions>-->
<!--          </v-card>-->
<!--        </v-dialog>-->
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ item }">
      {{ report_add_cashs.indexOf(item) + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_credit_customers from '../../../services/creditCustomer'
import ModalAddCredit from './ModalAddCredit';

export default {
  components :{
    ModalAddCredit
  },
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    customer_id : '',
    report_add_cashs: [],
    dialogCash: false,
    dialogCredit: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      { text: 'ลูกค้า', value: 'member.member_name' , sortable: false},
      { text: 'จำนวนตัด/เพิ่ม', value: 'amount_credit' },
      { text: 'จำนวนคงเหลือเครดิต', value: 'remain' },
      { text: 'อัพเดทล่าสุด', value: 'created_at' },
      { text: 'พนักงาน', value: 'employee.employee_name', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      numberCustomer: '',
      status: '',
      numberTax: null,
      nameCustomer: null,
      credit: null,
      dateBill: '',
      dateGetMoney: '',
    },
    defaultItem: {
      numberCustomer: '',
      status: '',
      numberTax: null,
      nameCustomer: null,
      credit: null,
      dateBill: '',
      dateGetMoney: '',
    },
  }),

  computed: {
    formCredit() {
      return this.editedIndex === -1 ? ' เติมเครดิต' : 'แก้ไข กลุ่มพนักงาน'
    },
    formCash() {
      return this.editedIndex === -1 ? ' เติมเงิน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    this.customer_id = this.$route.params.id;
    this.getReportCredit();
    // this.getUser()
  },
  methods: {
    getReportCredit(){
      this.$store.state.isLoading = true
      var data =  {
        search : this.search,
        id : this.customer_id

      }
      instance_credit_customers.getCreditDetail(data).then(res => {
        this.report_add_cashs = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
